import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import uuid from "uuid-random"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Spacer from "../dotgrid/spacer"

const Container = styled.section`
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};
`

const Iframe = styled.iframe`
  width: 100%;
  height: 10em;
`

const PardotForm = ({ data }) => {
  if (!data || !data.acf || !data.acf.url) return null

  const { title, subtitle, url } = data.acf

  const iframeRef = useRef()
  const iframeIdRef = useRef(uuid())
  const iframeHeightReceivedRef = useRef(false)

  const postMessage = () => {
    if (!iframeRef.current.contentWindow) return

    iframeRef.current.contentWindow.postMessage({ action: `cintLoaded` }, `*`)
  }

  const winMessage = (e) => {
    if (
      iframeRef.current &&
      e &&
      e.data &&
      e.data.id == iframeRef.current.name &&
      e.data.height
    ) {
      iframeRef.current.style.height = `${e.data.height}px`

      if (!iframeHeightReceivedRef.current) {
        iframeHeightReceivedRef.current = true
        postMessage()
      }
    }
  }

  useEffect(() => {
    window.addEventListener(`message`, winMessage)

    if (iframeRef.current) {
      postMessage()
      iframeRef.current.addEventListener(`load`, postMessage)
    }

    return () => {
      window.removeEventListener(`message`, winMessage)
    }
  }, [])

  return (
    <Container>
      <Grid>
        {(title || subtitle) && (
          <Row pullY={2}>
            <Cell
              size={11}
              mq={{
                largeDown: { size: 14 },
                mediumDown: { size: 18 },
                smallDown: { size: 23 },
              }}
              className="styled"
            >
              {!!title && (
                <h2
                  className="do-unstyle styled-h2"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}

              {!title && !!subtitle && (
                <h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
              )}

              {!!title && !!subtitle && (
                <p dangerouslySetInnerHTML={{ __html: subtitle }} />
              )}
            </Cell>
          </Row>
        )}

        <Row>
          <Cell
            size={11}
            mq={{
              largeDown: { size: 14 },
              mediumDown: { size: 18 },
              smallDown: { size: 23 },
            }}
          >
            <Iframe
              ref={iframeRef}
              name={iframeIdRef.current}
              src={url}
              scrolling="no"
              loading="lazy"
              data-cookieconsent="ignore"
            />
          </Cell>
        </Row>
      </Grid>

      <Grid noTopPadding>
        <Spacer dots={{ color: `#fff`, opacity: 0.2 }} size={4} />
      </Grid>
    </Container>
  )
}

PardotForm.propTypes = {
  data: PropTypes.object,
}

export default PardotForm
